import React, { useState } from 'react';
import './App.scss';
import Header from './components/Header';
import SsTable from './components/SsTable';
import Settings from './components/Settings';
import Help from './components/Help';
import { useStickyState} from './libs/hooksLib';

function App() {
  // Locally stored settings.
  const [ showCookieWarning, setShowCookiewarning ] = useStickyState(true, "showCookieWarning");
  const [ useImperial, setUseImperial ]             = useStickyState(false, "useImperial");
  const [ useRadius, setUseRadius ]                 = useStickyState(false, "useRadius");
  const [ distanceOnX, setDistanceOnX ]             = useStickyState(true, "distanceOnX");
  const [ compactTable, setCompactTable ]           = useStickyState(false, "compactTable");
  const [ sMin, setSMin ]                           = useStickyState(2, "sMin");
  const [ sStep, setSStep ]                         = useStickyState(2, "sStep");
  const [ sMax, setSMax ]                           = useStickyState(20, "sMax");
  const [ vMin, setVMin ]                           = useStickyState(100, "vMin");
  const [ vMax, setVMax ]                           = useStickyState(1000, "vMax");
  const [ vStep, setVStep ]                         = useStickyState(20, "vStep");
  // State.
  const [ showSettings, setShowSettings ] = useState(false);
  const [ showHelp, setShowHelp ]         = useState(false);

  const convertFunction = (xValue, yValue) => {
    const v = distanceOnX ? yValue : xValue;
    const s = distanceOnX ? xValue : yValue;
    const D = useRadius ? s : s * 2;

    // Cutting speed vc = pi * diameter * speed
    let surfaceSpeed = Math.PI * D * v;
    // Convert to m/min or sfm.
    surfaceSpeed = useImperial ? surfaceSpeed / 12 : surfaceSpeed / 1000;
    return surfaceSpeed.toFixed(0)
  }

  function getDistanceLabel() {
    const measure = useRadius ? "r" : "D";
    const unit    = useImperial ? "sfm" : "m/min";
    return `${measure} (${unit})`
  }

  return (
    <div className="d-flex flex-column min-vh-100">
      <Header
        setShowSettings = {setShowSettings}
        setShowHelp     = {setShowHelp}
      />
      {
        showSettings ? 
        <Settings
          showSettings         = {showSettings}
          setShowSettings      = {setShowSettings}
          showCookieWarning    = {showCookieWarning}
          setShowCookiewarning = {setShowCookiewarning}
          // Settings.
          setUseImperial  = {setUseImperial}
          useRadius       = {useRadius}
          setUseRadius    = {setUseRadius}
          distanceOnX     = {distanceOnX}
          setDistanceOnX  = {setDistanceOnX}
          compactTable    = {compactTable}
          setCompactTable = {setCompactTable}
          sMin            = {sMin}
          setSMin         = {setSMin}
          sStep           = {sStep}
          setSStep        = {setSStep}
          sMax            = {sMax}
          setSMax         = {setSMax}
          vMin            = {vMin}
          setVMin         = {setVMin}
          vMax            = {vMax}
          setVMax         = {setVMax}
          vStep           = {vStep}
          setVStep        = {setVStep}
          useImperial     = {useImperial}
        /> : null
      }
      <Help
        showHelp    = {showHelp}
        setShowHelp = {setShowHelp}
      />
      <SsTable
        convertCallback = {convertFunction}
        compactTable    = {compactTable}
        // x.
        xLabel = { distanceOnX ? getDistanceLabel() : "&omega; (rpm)" }
        xMin   = { distanceOnX ? sMin : vMin }
        xStep  = { distanceOnX ? sStep : vStep }
        xMax   = { distanceOnX ? sMax : vMax }
        // y.
        yLabel = { distanceOnX ? "&omega; (rpm)" : getDistanceLabel() }
        yMin   = { distanceOnX ? vMin : sMin }
        yStep  = { distanceOnX ? vStep : sStep }
        yMax   = { distanceOnX ? vMax : sMax }
      />
    </div>
  );
}

export default App;
