import { useState, useEffect } from 'react';

/**
 * @callback useFormFieldsCallback
 * @param fieldId
 *   Id of the field being set.
 * @param fieldValue
 *   Value of field being set.
 */
/**
 * Returns a custom setter for form fields.
 *
 * If all fields should be set in one go pass an object with all data as
 * the fields param i.e. {field: {<all fields>}}
 *
 * @param {array} initialState
 *   Initial state.
 * @param {useFormFieldsCallback} callback
 *   Callback function to run after setting fields.
 */
 export function useFormFields(initialState, callback = null) {
  const [fields, setValues] = useState(initialState);

  return [
    fields,
    function(event) {
      if (callback !== null) {
        callback(event.target.id, event.target.value);
      }
      if(event.hasOwnProperty('fields')) {
        setValues(event.fields);
      }
      else {
        // We may be called by a checkbox.
        if(event.target.hasOwnProperty('checked')) {
          setValues({
            ...fields,
            [event.target.id]: event.target.checked
          });
        }
        else {
          setValues({
            ...fields,
            [event.target.id]: event.target.value
          });
        }
      }
    }
  ];
}

/**
 * Stores values persistently in localstorage.
 *
 * https://www.joshwcomeau.com/react/persisting-react-state-in-localstorage/
 * Use like this:
 * const [
 *   value,
 *   setValue
 * ] = useStickyState(<default>, "<key>");
 *
 * @param {*} defaultValue
 *    Default value to return if no value found in storage.
 * @param {*} key 
 *    Key to store value in
 *
 * @returns
 *    Value.
 */
export function useStickyState(defaultValue, key) {
  const [isInitialised, setIsInitialised] = useState(false);
  const [value, setValue] = useState(() => {
    let stickyValue = null;
    try {
      stickyValue = window.localStorage.getItem(key);
    }
    catch (error) {
      console.error(error);
    }
    return stickyValue !== null
      ? JSON.parse(stickyValue)
      : defaultValue;
  });
  useEffect(() => {
    try {
      // Only store to local storage after initialisation.
      isInitialised ?
      window.localStorage.setItem(key, JSON.stringify(value)) :
      setIsInitialised(true);
    }
    catch (error) {
      console.error(error);
    }
  // We don't want eslint complaining about the missing dependency
  // isInitialised. If we add it useEffect will re-run when it changes
  // defeating the purpose.
  // eslint-disable-next-line
  }, [key, value]);
  return [value, setValue];
}
