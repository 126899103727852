import React from 'react';
import { Navbar, Nav, Row, Col } from 'react-bootstrap';
import './Header.scss'
import { ReactComponent as Logo } from '../assets/ssc.svg';
import { ReactComponent as Gear } from '../assets/gear.svg';
import { ReactComponent as QuestionMark } from '../assets/questionmark.svg';

export default function Navigation({
  setShowSettings,
  setShowHelp,
  ...props
}) {
  // Return an empty component if there are no errors.
  return (
    <Navbar expand="md" collapseOnSelect>
      <Navbar.Brand>
        <Row>
          <Col>
            <Logo
              style={{height:"40px", width: "40px"}}
              className="d-inline-block align-top primarySvg ps-2"
              alt="Surface Speed Calculator"
            />
          </Col>
          <Col className="my-auto title">
            Surface Speed Calculator
          </Col>
        </Row>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="me-auto" />
        <Nav>
          <Nav.Item>
            <Nav.Link
              onClick={() => setShowHelp(true)}
            >
              <QuestionMark
                style={{height:"40px", width: "40px"}}
                className="primarySvg"
                alt = "Help."
              />
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              onClick={() => setShowSettings(true)}
            >
              <Gear
                style={{height:"40px", width: "40px"}}
                className="primarySvg"
                alt = "Settings."
              />
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}