import React from 'react';
import { Modal, Button } from 'react-bootstrap';

export default function Help({
  showHelp, setShowHelp,
  ...props
}) {

  function getHelpText() {
    return(
      <>
        <h4>About</h4>
        <p>
          This application generates a table of surface speeds for a range of
          angular velocities and distances.
          The page can easily be printed from the browser by selecting options
          and then print, or by hitting ctrl + P (cmd + P on the mac).
        </p>
        <p>
          The application was created for free by&nbsp;
          <a href="https://dcol.nl">Daniel Colon</a>. There are no ads or
          tracking, and all source code is available&nbsp;
          <a href="https://gitlab.com/dcolon/ssc">here</a>. It is built using&nbsp;
          <a href="https://create-react-app.dev/">create-react-app.</a>
        </p>
        <h4>Units</h4>
        <p>
          The application supports both metric and imperial units.
          Distances are in mm/inches. Angular velocity in rotations/minute.
          Surface speeds are calculated in m/min or or feet/minute (sfm).
        </p>
        <h4>Function</h4>
        <p>
          The application has only one function: to render a table of surface
          speeds for a set of workpiece diameters/radii and angular velocities.
          The table is generated by stepping from minimum to maximum values for
          speed and distance on the axes, and displaying the corresponding
          surface speed in the corresponding cell in the table.
          The speed is derived from the distance (where distance D is either
          twice the radius (when in radius mode), or the diameter) and speed:
          <br/>
          <code>surfaceSpeed =  &pi; * D * &omega;</code>
          <br/>
          The speed is then transformed to a more readable dimension (m or feet
          rather than mm or inches):
          <br/>
          <code>surfaceSpeed =  surfaceSpeed / 1000</code> (metric)
          <br/>
          <code>surfaceSpeed =  surfaceSpeed / 12</code> (imperial)
        </p>
        <h4>Configuration</h4>
        <p>
          The application can be configured by clicking the gear icon on the
          top right.
        </p>
        <p>
          In the top box, the application can be put in radius or diameter mode.
          This determines whether the distance shown and used in the calculation
          is radius or diameter.
          The second toggle can be used to switch the x- and y-axes.
        </p>
        <p>
          <b>Tip:</b> when you double-click a text field it will highlight the
          current entry and be easily overridden when you start typing.
        </p>
        <p>
          The settings for distance s (either radius or diameter) can be
          set in the middle box. An entry will be displayed on the table for
          each step increasing by step-size from the minimum to the maximum.
          The maximum value will always be shown even if it is not a multiple
          of the step size.
        </p>
        <p>
          For example, the following settings:<br/>
          <ul>
            <li>Minimum: 5</li>
            <li>Step size: 10</li>
            <li>Maximum 30</li>
          </ul>
          Will yield:
          <ul>
            <li>5</li>
            <li>15</li>
            <li>25</li>
            <li>30</li>
          </ul>
        </p>
        <p>
          The third box is used to set rotational velocity in revolutions per
          unit time. This works exactly like the settings for distance.
        </p>
        <p>
          Finally click save to store your settings. Settings will be stored in
          a cookie on your device, so they will be remembered if you return to
          SSC later. Settings won't be saved (or used to render the table) until
          you have clicked "Save changes".
        </p>
      </>
    )
  }

  /**
   * Return modal with settings.
   */
  return (
    <Modal show={showHelp} onHide={() => setShowHelp(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Help</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {getHelpText()}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => setShowHelp(false)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}