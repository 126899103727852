import React from 'react';
import { Modal, Alert, Form, Button, Row, Col } from 'react-bootstrap';
import { useFormFields } from '../libs/hooksLib';

export default function Settings({
  showCookieWarning, setShowCookiewarning,
  showSettings, setShowSettings,
  useImperial, setUseImperial,
  useRadius, setUseRadius,
  distanceOnX, setDistanceOnX,
  sMin, setSMin,
  sStep, setSStep,
  sMax, setSMax,
  vMin, setVMin,
  vMax, setVMax,
  vStep, setVStep,
  compactTable, setCompactTable,
  ...props
}) {
  // Keep a local copy of settings.
  // We'll only write them back to app on save.
  const [ fields, handleFieldChange ] = useFormFields({
    useImperialForm: useImperial,
    useRadiusForm: useRadius,
    distanceOnXForm: distanceOnX,
    compactTableForm: compactTable,
    sMinForm: sMin,
    sStepForm: sStep,
    sMaxForm: sMax,
    vMinForm: vMin,
    vStepForm: vStep,
    vMaxForm: vMax,
  });

  /**
   * Loads main application settings to form settings.
   */
  function loadSettings() {
    handleFieldChange(
      {
        fields: {
          useImperialForm:  useImperial,
          useRadiusForm:    useRadius,
          distanceOnXForm:  distanceOnX,
          compactTableForm: compactTable,
          sMinForm:         sMin,
          sStepForm:        sStep,
          sMaxForm:         sMax,
          vMinForm:         vMin,
          vStepForm:        vStep,
          vMaxForm:         vMax,
        }
      }
    )
  }

  /**
   * Saves form settings to main application settings.
   */
  function saveSettings() {
    // Don't show the cookie warning again.
    setShowCookiewarning(false);
    // Write our form settings back to the App level.
    setUseImperial(fields.useImperialForm)
    setUseRadius(fields.useRadiusForm);
    setDistanceOnX(fields.distanceOnXForm);
    setCompactTable(fields.compactTableForm);
    setSMin(parseInt(fields.sMinForm));
    setSStep(parseInt(fields.sStepForm));
    setSMax(parseInt(fields.sMaxForm));
    setVMin(parseInt(fields.vMinForm));
    setVStep(parseInt(fields.vStepForm));
    setVMax(parseInt(fields.vMaxForm));
  }

  /**
   * When you doubleclick anywhere on the text field we want to select the text.
   * Default behaviour appears to only do it when the actual text is clicked
   * which is annoying.
   *
   * @param {event} event
   *    Doubleclick event.
   */
  function doubleClickHandler(event) {
    event.target.select();
  }

  /**
   * Return modal with settings.
   */
  return (
    <Modal
      id="settingsModal"
      show={showSettings}
      onHide={() => setShowSettings(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>Settings</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pb-0">
        { 
          showCookieWarning ? 
          <Alert type="info">
            These settings are saved on your machine using cookies. This is the
            only thing this application uses cookies for (no tracking or ads!).
            By clicking "Save" you consent to this use of cookies.
          </Alert>
          : null
        }
        <Form>
          <Form.Group className="mb-3 p-3 pb-0 border rounded">
          <Form.Group className="mb-3">
              <Form.Check 
                type="switch"
                id="useImperialForm"
                label="Use imperial"
                value="useImperialForm"
                checked={fields.useImperialForm}
                onChange={handleFieldChange}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Check 
                type="switch"
                id="useRadiusForm"
                label="Use radius"
                value="useRadiusForm"
                checked={fields.useRadiusForm}
                onChange={handleFieldChange}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Check 
                type="switch"
                id="distanceOnXForm"
                label={`Use x-axis for ${fields.useRadiusForm ? 'radius' : 'diameter'}.`}
                value="distanceOnXForm"
                checked={fields.distanceOnXForm}
                onChange={handleFieldChange}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Check 
                type="switch"
                id="compactTableForm"
                label="Use compact table."
                value="compactTableForm"
                checked={fields.compactTableForm}
                onChange={handleFieldChange}
              />
            </Form.Group>
          </Form.Group>
          <Row>
            <Col>
              <Form.Group className="mb-3 p-3 border rounded">
                <h5>
                  {
                    `${
                      fields.useRadiusForm ? 'Radius (r)' : 'Diameter (s)'
                    }`
                  }
                  <br/>
                  {
                    `(${
                      fields.useImperialForm ? 'inch' : 'mm'
                    })`
                  }
                </h5>

                <Form.Label htmlFor="sMinForm" className="form-label mt-2">Minimum</Form.Label>
                <Form.Control 
                  id="sMinForm"
                  placeholder="Minimum"
                  type="number"
                  min = "0"
                  step = "1"
                  value={fields.sMinForm}
                  onChange={handleFieldChange}
                  onDoubleClick={doubleClickHandler}
                />

                <Form.Label htmlFor="sStepForm" className="form-label mt-2">Step</Form.Label>
                <Form.Control 
                  id="sStepForm"
                  placeholder="Step"
                  type="number"
                  min = "0"
                  step = "1"
                  value={fields.sStepForm}
                  onChange={handleFieldChange}
                  onDoubleClick={doubleClickHandler}
                />

                <Form.Label htmlFor="sMaxForm" className="form-label mt-2">Maximum</Form.Label>
                <Form.Control 
                  id="sMaxForm"
                  placeholder="Max"
                  type="number"
                  min = "0"
                  step = "1"
                  value={fields.sMaxForm}
                  onChange={handleFieldChange}
                  onDoubleClick={doubleClickHandler}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-0 p-3 border rounded">
                <h5>Angular velocity<br/>(&omega;) (rpm)</h5>

                <Form.Label htmlFor="vMinForm" className="form-label mt-2">Minimum</Form.Label>
                <Form.Control 
                  id="vMinForm"
                  placeholder="Minimum"
                  type="number"
                  min = "0"
                  step = "1"
                  value={fields.vMinForm}
                  onChange={handleFieldChange}
                  onDoubleClick={doubleClickHandler}
                />

                <Form.Label htmlFor="vStepForm" className="form-label mt-2">Step</Form.Label>
                <Form.Control 
                  id="vStepForm"
                  placeholder="Step"
                  type="number"
                  min = "0"
                  step = "1"
                  value={fields.vStepForm}
                  onChange={handleFieldChange}
                  onDoubleClick={doubleClickHandler}
                />

                <Form.Label htmlFor="vMaxForm" className="form-label mt-2">Maximum</Form.Label>
                <Form.Control 
                  id="vMaxForm"
                  placeholder="Max"
                  type="number"
                  min = "0"
                  step = "1"
                  value={fields.vMaxForm}
                  onChange={handleFieldChange}
                  onDoubleClick={doubleClickHandler}
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>   
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={
            () => {
              saveSettings();
              setShowSettings(false);
            }
          }
        >
          Save changes
        </Button>
        <Button variant="secondary" onClick={() => loadSettings()}>
          Undo changes
        </Button>
        <Button variant="secondary" onClick={() => setShowSettings(false)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}