import React from 'react';
import { Container, Table } from 'react-bootstrap';
import './SsTable.css';

export default function SsTable({
  compactTable = true,
  xMin = 2,
  xStep = 2,
  xMax = 20,
  yMin = 30,
  yMax = 1000,
  yStep = 10,
  xLabel = "D",
  yLabel = "&omega;",
  convertCallback = null,
  ...props
}) {

  /**
   * Callback to convert an x value.
   *
   * This callback converts an x value passed in and returns the modifed value.
   *
   * @callback convertXCallback
   * @param {int} xValue 
   *    An integer x value to convert.
   * @return {string}
   *    A converted value to display.
   */

  /**
   * Returns an array of cells following the min, step and max.
   *
   * A callback function can be passed that will modify the value before
   * including it.
   *
   * @param {number} min
   *    The minimum value.
   * @param {number} step
   *    The step size.
   * @param {number} max
   *    The maximum value.
   * @param {convertXCallback} callback
   *    Callback to convert x value.
   */
  function getCells(min, step, max, callback = null, isHeader = false) {
    // If we weren't passed a callback just return the value.
    if(typeof callback !== 'function') {
      callback = (xValue) => { return(String(xValue)); };
    }
    let cells = [];
    let xValue = min
    for(min; xValue <= max; xValue = xValue + step) {
      cells.push(
        React.createElement(
          isHeader ? 'th' : 'td',
          {
            key: xValue
          },
          callback(xValue)
        )
      )
    }
    // Make sure we include the maximum value.
    if(xValue - step < max) {
      cells.push(
        React.createElement(
          isHeader ? 'th' : 'td',
          {
            key: xValue
          },
          callback(max)
        )
      )
    }
    return(cells);
  }

  /**
   * Returns html for the axis label.
   *
   * This prevents React from escaping the html character codes.
   * No risk of XSS as the all text is hard coded.
   *
   * @returns html
   *    Html of the label.
   */
  function getAxisLabel() {
    return ({__html: `&darr;${yLabel}&nbsp;&rarr;${xLabel}`});
  }

  /**
   * Renders the table ehader according to x configuration.
   * @returns 
   */
  function getHead() {
    const cells = getCells(
      xMin,
      xStep,
      xMax,
      null,
      true
    );
    return (
      <thead>
        <tr>
          <th dangerouslySetInnerHTML={getAxisLabel()} />
          { cells }
        </tr>
      </thead>
    )
  }

  /**
   * Returns a table body row for the given x and y value.
   *
   * @param {number} yValue
   *    Integer y-coordinate.
   */
  function getRow(yValue) {
    return(
      <tr key={yValue}>
        <td><b>{yValue}</b></td>
        {
          getCells(
            xMin,
            xStep,
            xMax,
            (xValue) => {
              return convertCallback(xValue, yValue);
            },
            false
          )
        }
      </tr>
    )
  }

  function getBody() {
    // First use the convert callback passed to us in props to build an x
    // callback specific to our y coordinate.
    let rows = [];
    let yValue = yMin
    for(yMin; yValue <= yMax; yValue = yValue + yStep) {
      rows.push(getRow(yValue));
    }
    // Make sure we include the maximum value.
    if(yValue - yStep < yMax) {
      rows.push(getRow(yMax));
    }

    return(
      <tbody>
        { rows }
      </tbody>
    )
  }

  /**
   * Returns the actual table.
   */
  return (
    <Container fluid className="px-0">
      <Table bordered striped hover size={compactTable ? "sm" : ""} className={compactTable ? "compact" : ""}>
        { getHead() }
        { getBody() }
      </Table>
    </Container>
  )
}